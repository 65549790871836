body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
  Fix for vertical alignment in passwordCheckList component
*/
.passwordChecklist li {
  align-items: center;
}
